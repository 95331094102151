import {Navigate, useLocation} from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface RequireAuthAndRoleProps {
    children: JSX.Element;
    requiredRole: string;
}

export default function RequireAuthAndRole({children, requiredRole}: RequireAuthAndRoleProps) {
    const {isAuthenticated, role} = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    if (role !== requiredRole) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}
