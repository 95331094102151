import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from "../../../components/Button/Button";
import Select from 'react-select';
import {
    IGetProductResponse,
    IGetInstitutions,
     IGetProductsByInstitution
} from "../../../ts/interfaces/Product/interface";
import { useMutation, useQuery } from "react-query";
import {
    deleteProductPci,
    sendProductPci,
    getInstitutions,
    getProductsByInstitution
} from "../../../services/services";
import { BarLoader } from "react-spinners";
import Pagination from "../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { adjustUtcDate, limitString } from "../../../helper/formatters";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { queryClient } from "../../../services/queryClient";
import { toast } from "react-toastify";


interface SelectOption {
    value: string;
    label: string;
}

const Product: React.FC = () => {
    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false);
    const [filter, setFilter] = useState<IGetProductsByInstitution>({
        institution: "",
    });

    const { data, isLoading, refetch } = useQuery<IGetProductResponse>(
        ["productByInstitution", filter],
        () => getProductsByInstitution(filter.institution),
        {
            staleTime: Infinity,
            enabled: !!filter.institution,
        }
    );

    const { data: institutionsData, isLoading: isLoadingInstitutions } = useQuery<IGetInstitutions[]>(
        ["institutions"],
        () => getInstitutions(),
        {
            staleTime: Infinity,
        }
    );

    const institutionOptions: SelectOption[] = institutionsData
        ? [
            { value: '', label: 'Selecione uma instituição' },
            ...institutionsData.map(inst => ({
                value: inst.instituicao_url,
                label: inst.instituicao,
            })),
        ]
        : [{ value: '', label: 'Selecione uma instituição' }];

    const [open, setOpen] = useState(false);
    const [openSendPci, setOpenSendPci] = useState(false);
    const [id, setId] = useState<number | null>(null);

    const mutateDeleteProduct = useMutation(
        () => {
            return deleteProductPci(id as number);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(["product", filter]);
                toast.success('Deletado com sucesso');
                setOpen(false);
            },
            onError: () => {
                toast.error("Erro ao deletar o produto");
            },
        }
    );

    const mutateSendProductPci = useMutation(
        () => {
            return sendProductPci(id as number);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(["product", filter]);
                setOpenSendPci(false);
                toast.success('Atualização enviada com sucesso');
                setOpen(false);
            },
            onError: () => {
                toast.error("Erro ao enviar a atualização");
            },
        }
    );

    const copyToClipboard =  (text: string) => {

            try {
                navigator.clipboard.writeText(text);
                toast.success("Link copiado com sucesso!");
            } catch {
                toast.error("Erro ao copiar link. Notifique nosso suporte");
            }

    };

    function handleCopy(product: any) {
        const sku = product.sku;
        const publisher = product.publisher.toLowerCase();
        let checkoutUrl = "";

        if (publisher.includes("nova")) {
            checkoutUrl = `https://api.novaconcursos.com.br/api/v1/campaign/add-item?channel=200&sku=${sku}`;
        } else if (publisher.includes("solução")) {
            checkoutUrl = `https://api.editorasolucao.com.br/api/v1/campaign/add-item?channel=200&sku=${sku}`;
        } else {
            toast.warn("Editora não reconhecida.");
            return;
        }
        copyToClipboard(checkoutUrl);
    }


    return (
        <div className={styles.container}>
            <Helmet>
                <title>Produtos</title>
            </Helmet>
            <h1>Lista de Produtos</h1>
            <Formik
                initialValues={{
                    institution: filter.institution || '',
                }}
                onSubmit={(values) => {
                    setFilter({
                        ...filter,
                        institution: values.institution,
                    });
                }}>

                {({ values, setFieldValue }) => (
                    <Form className={styles.searchProducts}>
                        <div className={styles.alignInput}>
                            <Select
                                options={institutionOptions}
                                onChange={(option) => setFieldValue('institution', option ? option.value : '')}
                                value={institutionOptions.find(option => option.value === values.institution) || null}
                                className={styles.select}
                                placeholder={isLoadingInstitutions ? "Carregando..." : "Selecione uma instituição"}
                                isLoading={isLoadingInstitutions}
                            />
                            <ErrorMessage name="institution" component="div" className={styles.error} />
                        </div>
                        <div className={styles.alignButtonSearch}>
                            <Button
                                type="submit"
                                width="115px"
                                height="39px"
                                theme="primary"
                                fontSize="14px"
                                disabled={isLoadingInstitutions}
                            >
                                Pesquisar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            {data?.data?.products?.some(p => Number(p.status) === 1) && (
                <div className={styles.groupCount}>
                    <div className={styles.productCountInline}>
                        Produtos ativos: <strong>{data.data.products.filter(p => Number(p.status) === 1).length}</strong>
                    </div>
                </div>
            )}
            <div className={styles.tableProducts}>
                {isLoading ? (
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : (
                    (data?.data?.products?.length ?? 0) > 0 ? (
                        <table>
                            <thead>
                            <tr>
                                <th>Editora</th>
                                <th>Capa</th>
                                <th>SKU</th>
                                <th>Atualizado</th>
                                <th>Nome</th>
                                <th>Tipo</th>
                                <th>Edição</th>
                                <th>Status</th>
                                <th>Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.data.products.map((product) => (
                                <tr key={product.id}>
                                    <td>{product.publisher}</td>
                                    <td>
                                        <img src={product.cover} alt="Capa" width={47} height={64} />
                                    </td>
                                    <td className={styles.alignTd}>{product.sku}</td>
                                    <td>{adjustUtcDate(product.createdAt)}</td>
                                    <td className={styles.alignTd}>{limitString(product.title, 40)}</td>
                                    <td>{product.type}</td>
                                    <td>{product.edition}</td>
                                    <td>
                                        {Number(product.status) === 1 ? 'Habilitado'
                                            : Number(product.status) === 2
                                                ? 'Desabilitado'
                                                : Number(product.status) === 3
                                                    ? 'Excluído'
                                                    : 'Desconhecido'}
                                    </td>
                                    <td>
                                        <Button
                                            width="100px"
                                            height="35px"
                                            fontSize="14px"
                                            onClick={() => handleCopy(product)}
                                        >
                                            Copiar Link
                                        </Button>

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className={styles.message} >Faça uma busca por instituição para listar os produtos</div>
                    )
                )}
            </div>

        </div>
    );
};

export default Product;