import api from './api';
import {UserLoginForm} from "../views/Login/Login";
import {ChangePasswordForm} from "../views/Login/ChangePassword";
import {IGetUser} from "../ts/interfaces/interface";
import {IPostProduct, IGetInstitutions, IGetProductsByInstitution} from '../ts/interfaces/Product/interface';
import {IGetProducts} from "../ts/interfaces/Product/interface";
import {IGetEmails, IPostEmail} from "../ts/interfaces/Email/interface";
import {IGetNotifications, IGetShowNotification} from "../ts/interfaces/Notification/interface";


// @ts-ignore
export const serialize = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

// @ts-ignore
export const serializeArray = (obj) => {
    let str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                for (let o in obj[p]) {
                    str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][o]));
                }
            } else {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    }
    return str.join("&");
}

//User
export const login = (data: UserLoginForm) => Promise.resolve(api.post('/v1/auth/login', data));

export const rememberPassword = (data: {
    email: string,
    token: string | null
}) => Promise.resolve(api.post(`/v1/auth/remember-password`, data));
export const changePassword = (data: ChangePasswordForm) => Promise.resolve(api.put(`/v1/auth/user/password`, data));
export const getUser = (): Promise<IGetUser> => (api.get(`/v1/auth/user/me`));

//Product 
export const getProduct = (id: number) => Promise.resolve(api.get('/v1/product/get/' + id));
export const storeProduct = (data: FormData) => Promise.resolve(api.post('/v1/product/store', data, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}));
export const getProducts = (data: IGetProducts) => Promise.resolve(api.get('/v1/product/get-all', {params: data}));
export const getProductsByInstitution = (institution: string) => Promise.resolve(api.get('/v1/product/get-by-institution/' + institution));
export const getSku = (sku: string) => Promise.resolve(api.get('/v1/product/get-sku/' + sku));
export const deleteProductPci = (id: number) => Promise.resolve(api.post('/v1/product/delete-pci/' + id));
export const sendProductPci = (id: number) => Promise.resolve(api.post('/v1/product/update-pci/' + id));


export const getUrlS3 = (data: {url: string, system_id: number}) => Promise.resolve(api.get('/v1/get-s3-file', {params: data}));

export const getEmails = (data: IGetEmails) => Promise.resolve(api.get('/v1/email/get-all', {params: data}));
export const getEmail = (id: number) => Promise.resolve(api.get('/v1/email/get/' + id));
export const storeEmail = (data: IPostEmail) => Promise.resolve(api.post('/v1/email/store', data));
export const deleteEmail = (id: number) => Promise.resolve(api.post('/v1/email/delete/' + id));

export const getNotifications = (data: IGetNotifications) => Promise.resolve(api.get('/v1/notification/get-all', {params: data}));
export const showViewNotification = (id: number) => Promise.resolve(api.get('/v1/notification/view/' + id));

export const getInstitutions = async (): Promise<IGetInstitutions[]> => {
    const response = await api.get('/v1/product/get-institutions');
    return response.data;
};

