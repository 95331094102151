import React from "react";
import {Route, Routes as Router} from "react-router-dom";

import Main from "../containers/Main";

import Dashboard from "../views/Dashboard/Dashboard";
import NotFound from "../views/NotFound/NotFound";
import Login from "../views/Login/Login";
import RequireAuth from "./RequireAuth";
import ChangePassword from "../views/Login/ChangePassword";
import RememberPassword from "../views/Login/RememberPassword";
import CreateProduct from "../views/Product/Create/CreateProduct";
import Product from "../views/Product/Product";
import Email from "../views/Notifications/Email/Email";
import Notifications from "../views/Notifications/Notifications/Notifications";
import RequireAuthAndRole from "./RequireAuthAndRole";
import {UserRoles} from "../ts/interfaces/Jwt/enum";
import ListProducts from "../views/Product/ListProducts/ListProducts";

export default function AppRouter() {
    return (
        <Router>
            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/recuperar-senha"} element={<RememberPassword/>}/>
            <Route path={"/alterar-senha"} element={<ChangePassword/>}/>
            <Route
                element={
                    <RequireAuthAndRole requiredRole={UserRoles.ADMIN}>
                        <Main/>
                    </RequireAuthAndRole>
                }
            >
                <Route
                    path={"/produto/cadastrar"}
                    element={<CreateProduct />}
                />
                <Route
                    path={"/produto/:id"}
                    element={<CreateProduct />}
                />
                <Route path={"/produtos"} element={<Product/>}/>
                <Route path={"/notificacoes/emails"} element={<Email/>}/>
                <Route path={"/notificacoes"} element={<Notifications/>}/>
            </Route>

            <Route element={<RequireAuth><Main /></RequireAuth>}>
                <Route path="/" element={<Dashboard />} />
                <Route path={'listagem-produtos'} element={<ListProducts/>}/>
            </Route>

            <Route path="*" element={<NotFound/>}/>
        </Router>
    );
}
