import { useMemo } from 'react';
import getCookie from '../helper/getCookie' // ou sua função de getCookie
import {jwtDecode} from 'jwt-decode';
import {CustomJwtPayload} from "../ts/interfaces/Jwt/interface";

export default function useAuth() {
  const jwtToken = getCookie('jwt_token_pci_');
  const authData = useMemo(() => {
    if (!jwtToken) {
      return { isAuthenticated: false, role: null };
    }

    try {
      const decodedToken = jwtDecode<CustomJwtPayload>(jwtToken);
      return {
        isAuthenticated: true,
        role: decodedToken.role,
      };
    } catch (error) {
      return { isAuthenticated: false, role: null };
    }
  }, [jwtToken]);

  return authData;
}