import React, {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';

import styles from "./styles.module.scss";

import {Formik, Form, Field, ErrorMessage} from 'formik';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useCookies} from "react-cookie";
import {useLocation, useNavigate} from "react-router-dom";
import {useMutation} from "react-query";
import {toast} from "react-toastify";

import {login} from "../../services/services";
import useAuth from "../../hooks/useAuth";
import Button from "../../components/Button/Button";
import logo from "../../assets/icons/logo.svg";

export interface UserLoginForm {
    email: string,
    password?: string,
    social_origin?: string,
    access_token?: string,
    sub?: string,
    token?: string | null
}

const Login: FC = () => {

    document.title = 'Login';

    const {search} = useLocation();
    const {isAuthenticated} = useAuth();
    const next = new URLSearchParams(search).get('next');

    const {executeRecaptcha} = useGoogleReCaptcha();

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const [cookies, setCookie] = useCookies(["jwt_token_pci_", "frontendname"]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
        password: Yup.string().required('Senha é obrigatória')
            .min(6, 'A senha deve ter no mínimo 6 caracteres'),
    });

    const initialValues = {
        email: "",
        password: "",
    };


    const {mutate, isLoading} = useMutation((data: UserLoginForm) => {
        return login(data)
    }, {
        onSettled: (response) => {
            let data = response?.data;
            if (data) {
                if (data.token) {
                    localStorage.setItem('jwt_token_pci_', data.token);
                    setCookie("jwt_token_pci_", data.token, {
                        domain: window.location.hostname,
                        path: "/",
                        maxAge: 3600 * 24 * (process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS ? parseInt(process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS) : 30)
                    });
                    toast.success("Login com sucesso.");

                    if (next) {
                        window.location.href = next.includes("http://") || next.includes("https://") ? next : '/' + next;
                    }

                    navigate('/');
                }

                if (data.error) {
                    toast.error(data.msg);
                }
            }
        },
        onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
            toast.error(error.message);
        }
    });

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated]);

    const onSubmit = async (data: UserLoginForm) => {
        let token = null;
        if (executeRecaptcha) {
            token = await executeRecaptcha('login');
        }
        mutate({...data, token: token})

    };

    return (
        <div className={styles.container}>
            <div className={styles.alignTitle}>
                <img className={styles.logo} src={logo} alt={'logo'}/>
            </div>
            <div className={styles.align}>
            <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => onSubmit(values)}
                >
                    <Form>
                        <div className={styles.titleGroup}>
                            <h1>Entrar</h1>
                        </div>
                        <div className={styles.inputContainer}>
                            <Field
                                className={styles.inputText}
                                type="email"
                                placeholder="E-mail"
                                name="email"
                                disabled={isLoading}
                            />
                            <ErrorMessage name="email" component="div" className={styles.error}/>
                        </div>
                        <div className={styles.passwordContainer}>
                            <Field
                                className={styles.inputText}
                                type={showPassword ? "text" : "password"}
                                placeholder="Senha"
                                name="password"
                                disabled={isLoading}
                            />
                            {/*<img src={eyeIcon} alt={showPassword ? 'Ocultar senha' : 'Mostrar senha'}*/}
                            {/*     onClick={togglePasswordVisibility}*/}
                            {/*/>*/}
                            <ErrorMessage name="password" component="div" className={styles.error}/>
                        </div>
                        <div className={styles.remember}>
                            <Field
                                id="lembrar"
                                name="remember"
                                type="checkbox"
                                className={styles.checkbox}
                                disabled={isLoading}
                            />
                            <label htmlFor="lembrar" className={styles.checkboxLabel}>Lembrar</label>
                        </div>
                        <Button
                            height="39px"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Entrando...' : 'Entrar'}
                        </Button>
                        <a href="/recuperar-senha">Recuperar senha</a>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

const TheLogin = () => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""}>
            <Login/>
        </GoogleReCaptchaProvider>
    )
}

export default TheLogin;